import React from "react"
import { Link } from "gatsby"
import BudiUpdated from "../../components/de/updated"

import Layout from "../../components/de/layout"
//import Image from "../components/image"
import SEO from "../../components/de/seo"

import MainImg from "../../images/main_bg.jpg"

import shape1 from "../../images/dec/1.png"
import shape2 from "../../images/dec/2.png"
import shape3 from "../../images/dec/3.png"
import shape4 from "../../images/dec/4.png"
import shape5 from "../../images/dec/5.png"
import shape6 from "../../images/dec/6.png"
import shape7 from "../../images/dec/7.png"
import shape8 from "../../images/dec/8.png"
import logoHeadline from "../../images/herstellung-im-einklang-mit-der-natur.png"
import ourStoryImg from "../../images/produkte-mit-schwerpunkt-auf-naturlicher-herkunft-der-inhaltsstoffe.png"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import product1 from "../../images/moonamar-kaltgepresstes-bio-schwarzkummelol-250-ml.png"
import product1a from "../../images/moonamar-kaltgepresstes-bio-schwarzkummelol-100-ml.png"
import product2 from "../../images/moonamar-schwarzkummelol-in-halal-kapseln-250-stuck.png"
import product2a from "../../images/moonamar-schwarzkummelol-in-halal-kapseln-90-stuck.png"
import product3 from "../../images/moonamar-shampoo-mit-arganol-schwarzkummelol.png"
import product4 from "../../images/moonamar-hautbalsam-mit-argan-schwarzkummel-jojoba.png"
import product5 from "../../images/moonamar-sheabutterseife-mit-schwarzkummelol-vit-e.png"
import product6 from "../../images/moonamar-bonbons-mit-schwarzkummel-kurkuma-minze.png"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="IMMMUNO NATURA - Herstellung im Einklang mit der Natur"
          description="IMMMUNO NATURA - Exklusive Auswahl an Nahrungsergänzungen und Kosmetika mit Fokus auf natürlicher Herkunft wohltuender Inhaltsstoffe."
        />

        <section
          id="_hero"
          className="hero is-fullheight"
          style={{ backgroundImage: `url(${MainImg})` }}
        >
          <div id="bgHero">
            <span className="shape1">
              <img src={shape1} alt="Shape 1" />
            </span>
            <span className="shape2">
              <img src={shape2} alt="Shape 2" />
            </span>
            <span className="shape3">
              <img src={shape3} alt="Shape 3" />
            </span>
            <span className="shape4">
              <img src={shape4} alt="Shape 4" />
            </span>
            <span className="shape5">
              <img src={shape5} alt="Shape 5" />
            </span>
            <span className="shape6">
              <img src={shape6} alt="Shape 6" />
            </span>
            <span className="shape7">
              <img src={shape7} alt="Shape 7" />
            </span>
            <span className="shape8">
              <img src={shape2} alt="Shape 8" />
            </span>
            <span className="shape9">
              <img src={shape6} alt="Shape 9" />
            </span>
            <span className="shape10">
              <img src={shape3} alt="Shape 10" />
            </span>
            <span className="shape11">
              <img src={shape4} alt="Shape 11" />
            </span>
            <span className="shape12">
              <img src={shape1} alt="Shape 12" />
            </span>
            <span className="shape13">
              <img src={shape8} alt="Shape 13" />
            </span>
            <span className="shape14">
              <img src={shape7} alt="Shape 14" />
            </span>
            <span className="shape15">
              <img src={shape3} alt="Shape 15" />
            </span>
          </div>
          <div className="hero-body">
            <div className="container">
              <figure className="image">
                <img
                  src={logoHeadline}
                  alt="Nahrungsergänzungsmittel und Kosmetika"
                />
              </figure>
              <div className="title">IM EINKLANG </div>
              <div className="subtitle">mit der Natur</div>
              <div className="_inner">
                <BudiUpdated />
              </div>
            </div>
          </div>
        </section>

        <span className="_line" />

        <section id="our-story" className="section">
          <span className="_dec2">
            <img src={shape8} alt="Dec 2" />
          </span>
          <div className="container">
            <div className="columns">
              <div className="column is-3 _headline">
                <h2 className="title is-2">Exklusive Produktauswahl</h2>
                <p className="content">
                  IMMMUNO NATURA-Produkte entstehen als Ergebnis einer
                  Ergebenheit der Natur und sorgfältiger Arbeit an Rezepturen
                  mit wohltuenden Wirkungen.
                </p>
              </div>
              <div className="column">
                <figure className="image is-3by4">
                  <img
                    src={ourStoryImg}
                    alt="Produkte mit Schwerpunkt auf natürlicher Herkunft der Inhaltsstoffe"
                    title="Produkte mit Schwerpunkt auf natürlicher Herkunft der Inhaltsstoffe"
                  />
                </figure>
              </div>
              <div className="column is-3 content _headline">
                <h2 className="title is-2 is-spaced _gold">INSPIRATION</h2>
                <p className="subtitle is-4">
                  Schau tief in die Natur, und dann wirst du alles besser
                  verstehen. - <i>Albert Einstein</i>
                </p>
                <p className="content">
                  Jeder Mensch ist einzigartig in Bezug auf sein Aussehen, sein
                  Hormonsystem, sein Immunsystem, sowie in Bezug auf seine
                  emotionale und Charaktereigenschaften.
                </p>
                <p>
                  Dementsprechend entwickelt jeder Mensch unterschiedliche
                  Vorlieben und reagiert auf eigene Weise auf Reize aus seiner
                  Umgebung. Aus diesem Grund gilt das Gleiche bei der Auswahl
                  der richtigen Diät, Kosmetik, Nahrungsergänzungsmittel,
                  Diätprodukte usw.
                </p>
                <Link
                  to="/inspiration"
                  className="button is-text is-small btn2"
                  title="INSPIRATION AUS DER WEISHEIT DER NATUR"
                >
                  MEHR LESEN
                </Link>
              </div>
            </div>
          </div>
          <span className="_dec3">
            <img src={shape6} alt="Dec 3" />
          </span>
        </section>

        <section id="products" className="section">
          <div className="container is-fullhd">
            <div className="columns">
              <div className="column is-3 _headline">
                <h2 className="title is-2 is-spaced">
                  IMMMUNO NATURA PRODUKTE MIT SCHWARZKÜMMEL
                </h2>
                <p className="subtitle">
                  Inspiriert von der Tradition großer Religionen und Kulturen.
                </p>
                <p className="content">
                  Schwaches Immunsystem macht den Körper anfällig für
                  Infektionen und ein unausgeglichenes Immunsystem führt zu
                  allergischen Erkrankungen. Schwarzkümmel wirkt sich positiv
                  auf das Gleichgewicht der Immunabwehr und unterstützt somit
                  die Aufrechterhaltung der Gesundheit.
                </p>
                <p className="content">
                  Traditionell wird Schwarzkümmel als Unterstützung gegen
                  Allergien, als Nahrungsergänzung bei der Stärkung der
                  Immunabwehr und zur Haut- und Haarpflege eingesetzt.
                </p>
                <Link
                  to="/produkte"
                  className="button is-primary is-rounded is-small btn1head"
                  title="NAHRUNGSERGÄNZUNGSMITTEL UND KOSMETIK"
                >
                  ALLE PRODUKTE
                </Link>
              </div>
              <div className="column is-9">
                <Carousel
                  showStatus={false}
                  showIndicators={true}
                  showThumbs={false}
                >
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product1}
                      alt="KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 250 ML"
                      title="KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 250 ML"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>1</span>
                        <div className="title">
                          BIO-Schwarzkümmelöl (250 ml)
                        </div>
                        <p className="content">
                          IMMMUNO NATURA Schwarzkümmelöl wird durch Kaltpressen
                          gewonnen und keiner weiteren Verarbeitung ausgesetzt,
                          um die im Saatgut enthaltenen wohltuenden Substanzen
                          zu schonen.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/produkt/bio-schwarzkummelol"
                              title="KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 250 ML"
                              className="btnMore"
                            >
                              Mehr lesen
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/produkte"
                          title="NAHRUNGSERGÄNZUNGSMITTEL UND KOSMETIK"
                        >
                          ALLE PRODUKTE
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product1a}
                      alt="KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 100 ML"
                      title="KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 100 ML"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>2</span>
                        <div className="title">
                          BIO-Schwarzkümmelöl (100 ml)
                        </div>
                        <p className="content">
                          IMMMUNO NATURA Schwarzkümmelöl wird durch Kaltpressen
                          gewonnen und keiner weiteren Verarbeitung ausgesetzt,
                          um die im Saatgut enthaltenen wohltuenden Substanzen
                          zu schonen.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/produkt/bio-schwarzkummelol-1"
                              title="KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 100 ML"
                              className="btnMore"
                            >
                              Mehr lesen
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/produkte"
                          title="NAHRUNGSERGÄNZUNGSMITTEL UND KOSMETIK"
                        >
                          ALLE PRODUKTE
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product2a}
                      alt="SCHWARZKÜMMELÖL IN „HALAL-KAPSELN“ - 90 Stück"
                      title="SCHWARZKÜMMELÖL IN „HALAL-KAPSELN“ - 90 Stück"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>3</span>
                        <div className="title">
                          Schwarzkümmelöl in Kapseln (90 Stück)
                        </div>
                        <p className="content">
                          IMMMUNO NATURA Schwarzkümmelöl-Kapseln bestehen aus
                          einer halal-zertifizierten Gelatin-Hülle und aus
                          kaltgepresstem, jungfräulichem Öl, gewonnen aus
                          egyptischen Schwarzkümmelsamen.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/produkt/schwarzkummelol-in-kapseln-1"
                              title="SCHWARZKÜMMELÖL IN „HALAL-KAPSELN“ - 90 Stück"
                              className="btnMore"
                            >
                              Mehr lesen
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/produkte"
                          title="NAHRUNGSERGÄNZUNGSMITTEL UND KOSMETIK"
                        >
                          ALLE PRODUKTE
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product2}
                      alt="SCHWARZKÜMMELÖL IN HALAL-KAPSELN - 250 Stück"
                      title="SCHWARZKÜMMELÖL IN HALAL-KAPSELN - 250 Stück"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>4</span>
                        <div className="title">
                          Schwarzkümmelöl in Kapseln (250 Stück)
                        </div>
                        <p className="content">
                          IMMMUNO NATURA Schwarzkümmelöl-Kapseln bestehen aus
                          einer halal-zertifizierten Gelatin-Hülle und aus
                          kaltgepresstem, jungfräulichem Öl, gewonnen aus
                          egyptischen Schwarzkümmelsamen.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/produkt/schwarzkummelol-in-kapseln"
                              title="SCHWARZKÜMMELÖL IN HALAL-KAPSELN - 250 Stück"
                              className="btnMore"
                            >
                              Mehr lesen
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/produkte"
                          title="NAHRUNGSERGÄNZUNGSMITTEL UND KOSMETIK"
                        >
                          ALLE PRODUKTE
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product3}
                      alt="SHAMPOO MIT ARGANÖL & SCHWARZKÜMMELÖL"
                      title="SHAMPOO MIT ARGANÖL & SCHWARZKÜMMELÖL"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>5</span>
                        <div className="title">
                          Schampoo mit Argan und Schwarzkümmel
                        </div>
                        <p className="content">
                          IMMMUNO NATURA Shampoo mit Argan- und Schwarzkümmelöl
                          eignet sich als milde Haarreinigung für eine besonders
                          empfindliche Haut.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/produkt/schampoo-mit-argan-und-schwarzkummel"
                              title="SHAMPOO MIT ARGANÖL & SCHWARZKÜMMELÖL"
                              className="btnMore"
                            >
                              Mehr lesen
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/produkte"
                          title="NAHRUNGSERGÄNZUNGSMITTEL UND KOSMETIK"
                        >
                          ALLE PRODUKTE
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product4}
                      alt="HAUTBALSAM MIT ARGAN, SCHWARZKÜMMEL, JOJOBA"
                      title="HAUTBALSAM MIT ARGAN, SCHWARZKÜMMEL, JOJOBA"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>6</span>
                        <div className="title">
                          Hautbalsam mit Schwarzkümmel, Argan, Jojoba, Avocado &
                          Sheabutter
                        </div>
                        <p className="content">
                          IMMMUNO NATURA Balsam eignet sich als sehr
                          reichhaltige Pflege für trockene und empfindliche
                          Haut.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/produkt/hautbalsam-mit-schwarzkummel-argan-jojoba-avocado-sheabutter"
                              title="HAUTBALSAM MIT ARGAN, SCHWARZKÜMMEL, JOJOBA"
                              className="btnMore"
                            >
                              Mehr lesen
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/produkte"
                          title="NAHRUNGSERGÄNZUNGSMITTEL UND KOSMETIK"
                        >
                          ALLE PRODUKTE
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product5}
                      alt="SHEABUTTERSEIFE MIT SCHWARZKÜMMELÖL & VIT. E"
                      title="SHEABUTTERSEIFE MIT SCHWARZKÜMMELÖL & VIT. E"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>7</span>
                        <div className="title">
                          Sheabutterseife mit Schwarzkümmelöl
                        </div>
                        <p className="content">
                          IMMMUNO NATURA Sheabutterseife- für milde und
                          hautfreundliche Reinigung. Besonders geeignet für die
                          empfindliche und zu Allergien neigende Haut.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/produkt/sheabutterseife-mit-schwarzkummelol"
                              title="SHEABUTTERSEIFE MIT SCHWARZKÜMMELÖL & VIT. E"
                              className="btnMore"
                            >
                              Mehr lesen
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/produkte"
                          title="NAHRUNGSERGÄNZUNGSMITTEL UND KOSMETIK"
                        >
                          ALLE PRODUKTE
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product6}
                      alt="BONBONS MIT SCHWARZKÜMMEL, KURKUMA & MINZE"
                      title="BONBONS MIT SCHWARZKÜMMEL, KURKUMA & MINZE"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>8</span>
                        <div className="title">
                          Bonbons mit Schwarzkümmel, Kurkuma, Minze und Zitrone
                        </div>
                        <p className="content">
                          Wohlschmeckend und wohltuend für Hals und Atemwege.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/produkt/bonbons-mit-schwarzkummel-kurkuma-minze-und-zitrone"
                              className="btnMore"
                            >
                              Mehr lesen
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link to="/produkte">ALLE PRODUKTE</Link>
                      </div>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
          <span className="_dec1">
            <img src={shape7} alt="Dec 1" />
          </span>
          <span className="_dec1a">
            <img src={shape3} alt="Dec 1" />
          </span>
        </section>
      </Layout>
    )
  }
}

export default IndexPage
