import React from "react"
import ContactForm from "./newsletter"

export default class BudiUpdated extends React.Component {
  container = React.createRef()
  state = {
    open: false,
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }
  handleClickOutside = event => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        open: false,
      })
    }
  }
  handleButtonClick = () => {
    this.setState(state => {
      return {
        open: "is-active",
      }
    })
  }

  render() {
    return (
      <div class={"dropdown " + this.state.open} ref={this.container}>
        <div class="dropdown-trigger">
          <button
            class="button is-primary is-rounded is-medium btn1head"
            aria-haspopup="true"
            aria-controls="dropdown-menu3"
            onClick={this.handleButtonClick}
          >
            NEWSLETTER
          </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu3" role="menu">
          <div class="dropdown-content animated fadeInUp">
            <div className="dropdown-item">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
